/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply font-metaPro;
}

html {
  --mdc-dialog-container-color: #fff;
  --mdc-dialog-subhead-size: 1.25rem;
  --mdc-filled-text-field-container-color: #fff;
  --mdc-filled-text-field-container-shape: 0.35rem;
  --mat-form-field-container-height: 46px;
  --mat-form-field-filled-with-label-container-padding-top: 20px;
  --mdc-filled-text-field-label-text-size: 0.75rem;
  --mdc-filled-text-field-label-text-font: "MetaPro";
  --mdc-filled-text-field-label-text-weight: 400;
  --mat-form-field-subscript-text-font: "MetaPro";
  --mat-form-field-subscript-text-size: 0.75rem;
  --mat-form-field-subscript-text-weight: 400;
}

/* html, body { height: 100%; } */
/* body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; } */

.table thead td,
.table thead th {
  z-index: 9 !important;
}

.branding-bg {
  background-image: url("/media/images/2600x1200/bg-5.png");
  background-size: cover;
}

.demo1 {
  --tw-sidebar-width: 0px;
}

.ng-select {
  padding-bottom: 0px !important;
}

.mat-mdc-text-field-wrapper,
.ng-select-container {
  max-height: 48px;
}

.ng-dropdown-panel.ng-select-bottom {
  top: calc(100% - 0.25em);
}

.ng-select-container {
  background-color: var(--mdc-filled-text-field-container-color);
}

.ng-select-container {
  max-height: 48px;
  border-bottom-right-radius: var(--mdc-filled-text-field-container-shape) !important;
  border-bottom-left-radius: var(--mdc-filled-text-field-container-shape) !important;
}

.ng-select .ng-select-container {
  min-height: 48px;
}

.ng-select .ng-select-container .ng-value-container {
  padding: 0.4em 0;
  border-top: 1em solid transparent;
}

.mdc-text-field--filled,
.ng-select-container {
  border: 1px solid #d1dce1;
  border-top-left-radius: var(--mdc-filled-text-field-container-shape);
  border-top-right-radius: var(--mdc-filled-text-field-container-shape);
  border-bottom-right-radius: var(--mdc-filled-text-field-container-shape) !important;
  border-bottom-left-radius: var(--mdc-filled-text-field-container-shape) !important;
}

.mdc-text-field,
.ng-select-container,
.ng-input {
  padding: 0 10px !important;
}

.mdc-text-field__input,
input,
.ng-select input,
.ng-select span {
  font-family: "MetaPro" !important;
  font-size: 0.875rem !important;
}

.mat-mdc-form-field-hint-wrapper,
.mat-mdc-form-field-error-wrapper {
  padding: 0 0 !important;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  transform: translateY(-0.5em);
  color: var(--tw-dark) !important;
  font-family: var(--mdc-filled-text-field-label-text-font);
  font-size: var(--mdc-filled-text-field-label-text-size);
  font-weight: var(--mdc-filled-text-field-label-text-weight);
  letter-spacing: var(--mdc-filled-text-field-label-text-tracking);
}

.ng-select .ng-has-value .ng-placeholder,
.ng-select.ng-select-opened .ng-placeholder {
  transform: translateY(-1.5em) scale(0.8) perspective(100px) translateZ(0.001px) !important;
  color: var(--tw-dark) !important;
}

.ng-select.ng-select-focused .ng-select-container .ng-value-container .ng-placeholder {
  transform: translateY(-1.5em) scale(0.8) perspective(100px) translateZ(0.001px) !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  background-color: #e4edf1;
  border-radius: 50px;
  border: 1px solid #D1DCE1;
  color: var(--tw-dark);
  padding: 0px 8px;
  margin: 0 0.4375em 0.2em 0;
  max-height: 22px;
  line-height: normal;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon {
  color: var(--tw-dark);
}

/* .ng-select.ng-select-single .ng-select-container .ng-arrow-wrapper {
  bottom: 15px;
} */