@font-face {
  font-family: 'MetaPro';
  src: url('FFMetaProBlk.TTF') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'MetaPro';
  src: url('FFMetaProBlkIt.TTF') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'MetaPro';
  src: url('FFMetaProBold.TTF') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'MetaPro';
  src: url('FFMetaProBoldIt.TTF') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'MetaPro';
  src: url('FFMetaProBook.TTF') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'MetaPro';
  src: url('FFMetaProBookIt.TTF') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'MetaPro';
  src: url('FFMetaProCondBd.TTF') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'MetaPro';
  src: url('FFMetaProCondBdIt.TTF') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'MetaPro';
  src: url('FFMetaProCondBk.TTF') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'MetaPro';
  src: url('FFMetaProCondBkIt.TTF') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'MetaPro';
  src: url('FFMetaProCondBlk.TTF') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'MetaPro';
  src: url('FFMetaProCondBlkIt.TTF') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'MetaPro';
  src: url('FFMetaProCondMed.TTF') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'MetaPro';
  src: url('FFMetaProCondRg.TTF') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'MetaPro';
  src: url('FFMetaProCondRgIt.TTF') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'MetaPro';
  src: url('FFMetaProCondXBold.TTF') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'MetaPro';
  src: url('FFMetaProCondXBoldIt.TTF') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'MetaPro';
  src: url('FFMetaProLight.TTF') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'MetaPro';
  src: url('FFMetaProLightIt.TTF') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'MetaPro';
  src: url('FFMetaProMedIt.TTF') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'MetaPro';
  src: url('FFMetaProMedium.TTF') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'MetaPro';
  src: url('FFMetaProRegIt.TTF') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'MetaPro';
  src: url('FFMetaProRegular.TTF') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'MetaPro';
  src: url('FFMetaProThin.TTF') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'MetaPro';
  src: url('FFMetaProThinIt.TTF') format('truetype');
  font-weight: 100;
  font-style: italic;
}
